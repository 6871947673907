import React, { useEffect, useState } from 'react';
import './Passreset.css';
import { Link, useParams } from 'react-router-dom'

function Passreset() {

    const {id} = useParams();
    const [userid, setUserid] = useState('');
    const [ranstr, setRanstr] = useState('');
    const [password, setPassword] = useState('');
    const [conpass, setConpass] = useState('');
    const [smalltxt, setSmalltxt] = useState('');
    const [smalltxtcon, setSmalltxtcon] = useState('');
    const [popdis, setPopdis] = useState('none');

    useEffect(()=> {
        const idNran = id.split('-');
        setUserid(idNran[0]);
        setRanstr(idNran[1]);
    }, [id]);

    // useEffect(()=> console.log(userid, ranstr), [userid, ranstr]);

    const passchangeFunc = (pass)=> {
        if(!/[!@#$%^&*()_+\-=[\]{}|;:'",.<>/?`~\\]/.test(pass)){
            return setSmalltxt("password should contain atleast one spaecial character");
        }else if(!/\d/.test(pass)){
            return setSmalltxt("password should contain atleast one number");
        }else if(pass.length < 8){
            return setSmalltxt("password should contain atleast 8 characters");
        }else{
            return setSmalltxt('')
        }
    }

    const conpasschangeFunc = (pass)=> {
        if(pass !== password){
            return setSmalltxtcon('this should be same as password');
        }else{
            return setSmalltxtcon('')
        }
    }

    const resetpassFunc = () => {
        if(smalltxt === '' && smalltxtcon === ''){
            if(password === conpass){
                fetch('https://azdawaj.com/api/passreset', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"password": password, "randomstr": ranstr, "id": userid})
                }).then(res => res.json()).then(data => {
                    // console.log(data);
                    setPopdis('flex')
                }).catch(err => console.log('error occured while uploading new password ', err));
            }
        }
    }

  return (
    <div className='main-passreset'>
        <div className="main-wrap pt-5">
            <div className="main-reset-div mx-auto rounded-brand shadow bg-white p-3 mt-5 p-4 py-5">
                <p className="mb-0 body-font-bold fs-5">Set new password</p>
                <input type="password" className='form-control shadow-none outline-none border rounded-3 mt-2 p-2 px-3' value={password} onChange={(e)=> {setPassword(e.target.value); passchangeFunc(e.target.value)}} placeholder='Set you new password'/>
                {
                    smalltxt !== '' && (
                        <p className="small-alert mb-0 text-danger">{smalltxt}</p>
                    )
                }
                <p className="mb-0 body-font-bold mt-4 fs-5">Confirm password</p>
                <input type="password" className='form-control shadow-none outline-none border rounded-3 mt-2 p-2 px-3' value={conpass} onChange={(e)=> {setConpass(e.target.value); conpasschangeFunc(e.target.value)}} placeholder='Confirmed password'/>
                {
                    smalltxtcon !== '' && (
                        <p className="small-alert mb-0 text-danger">{smalltxtcon}</p>
                    )
                }
                <button className="btn btn-brand-pink p-2 px-3 rounded-3 mt-4" onClick={resetpassFunc}>Reset</button>
            </div>
        </div>
        <div className="done-pop-wrap" style={{display: popdis}}>
            <div className="done-popup bg-white rounded-brand p-3">
                <p className="mb-0 fs-5 text-center body-font-semibold">Password changed successfully</p>
                <div className="text-center mt-3">
                    <Link to='/' className='btn btn-brand-pink rounded-pill px-3'>Go Back</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Passreset