import React, { useState } from 'react';
import './Adminlogin.css';
import { useAuth } from '../../Context/Authcontext';
import { useNavigate } from 'react-router-dom';

function Adminlogin() {

    const { encodeJSON } = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const adminLoginFunc = () => {
        fetch(`https://azdawaj.com/api/superlogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"username": username, "password": password})
        }).then(res => res.json()).then(data => {
            // console.log(data);
            if(data.username){
                localStorage.setItem('adlocprodat', encodeJSON(data));
                navigate('/admin/dashboard')
            }
        }).catch(err => console.log('error occred while loggin in ', err));
    }

  return (
    <div className='main-adminlogin p-0'>
        <div className="login-pop shadow rounded-brand p-3 d-flex flex-column align-items-center justify-content-center bg-white py-4">
            <div className="mb-3 w-100">
                <input type="text" name="username" id="username" className='form-control shadow-none outline-none border rounded-3 p-2 px-3' placeholder='Enter Username' value={username} onChange={(e)=> setUsername(e.target.value)}/>
            </div>
            <div className="mb-3 w-100">
                <input type="password" name="password" id="password" className='form-control shadow-none outline-none border rounded-3 p-2 px-3' placeholder='Enter Password' value={password} onChange={(e)=> setPassword(e.target.value)}/>
            </div>
            <div className="sub-btn-admin w-100 text-start">
                <button className='btn btn-brand-pink p-2 px-3 rounded-3' onClick={adminLoginFunc}>Login</button>
            </div>
        </div>
    </div>
  )
}

export default Adminlogin;