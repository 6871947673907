import React, { useEffect, useState } from 'react';
import './Matched.css';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Matched() {

    const { matchedpros } = useAuth();
    const navigate = useNavigate();
    const [allpro, setAllpro] = useState([]);
    const [matchedpro, setMatchedpro] = useState([]);
    const [allids, setAllids] = useState([]);
    const [matpros, setMatpros] = useState([]);
    const [matchedobj, setMatchedobj] = useState([]);

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(!localuser || localuser.length < 1){
            navigate('/admin/');
        }
    }, [navigate])

    useEffect(()=> {
        fetch(`https://azdawaj.com`).then(res => res.json()).then(data => {
            setAllpro(data);
        }).catch(err => console.log('error occured while getting all the users', err));
    }, []);

    useEffect(()=> {
        const accArr = [];
        const matchedarr = [];
        allpro.forEach((pro) => {
            const matcharr = []
            if(pro.reqaccepted && pro.reqaccepted.length > 0){
                pro.reqaccepted.forEach(aid => {
                    matcharr.push([pro._id, aid]);
                    matchedarr.push([pro._id, aid])
                })
                accArr.push({id: pro._id, arr: pro.reqaccepted, matches: matcharr});
            };

            if(pro.reqstatus && pro.reqstatus.length > 0){
                const statusArr = [];
                pro.reqstatus.forEach(e => {
                    if(e.status === 'accepted'){
                        matchedarr.push([pro._id, e.rid]);
                        statusArr.push([pro._id, e.rid]);
                    }
                })
            }
        });
        const uniqueMatched = new Set(matchedarr.map(arr => JSON.stringify(arr)));
        const uniqueMatchedArr = Array.from(uniqueMatched).map(arr => JSON.parse(arr));
        setMatchedpro(uniqueMatchedArr);
        // console.log(uniqueMatchedArr);
    }, [allpro, matchedpros]);

    // useEffect(()=> console.log(matchedpros), [matchedpros]);

    useEffect(()=> {
        // console.log(matchedpro);
        const arr = [];
        matchedpro.forEach(pro => arr.push(...pro));
        const allId = [...new Set(arr)];
        // console.log('this is all id ', allId);
        setAllids(allId);
    }, [matchedpro]);

    useEffect(()=> {
        if(matchedpros && matchedpros.length >= 0){
            const temarr = [];
            matchedpros.forEach(pro => temarr.push(...pro));
            const allmaid = [...new Set(temarr)];
            Promise.all(allmaid.map(id => fetch(`https://azdawaj.com/api/profile/${id}`).then(res => res.ok? res.json(): res.status))).then(data => {
                // console.log(data);
                const dataarr = [];
                if(data.length > 0){
                    data.forEach(dat => {
                        if(dat !== 404){
                            dataarr.push(dat);
                        }
                    });
                    setMatpros(dataarr);
                }
            }).catch(err => console.log('error occured while getting all the matched profiles ', err));
        }else{
            Promise.all(allids.map(id => fetch(`https://azdawaj.com/api/profile/${id}`).then(res => res.ok? res.json(): res.status))).then(data => {
                // console.log(data);
                const dataarr = [];
                if(data.length > 0){
                    data.forEach(dat => {
                        if(dat !== 404){
                            dataarr.push(dat);
                        }
                    });
                    setMatpros(dataarr);
                }
            }).catch(err => console.log('error occured while getting all the matched profiles ', err));
        }
    }, [allids, matchedpros]);

    // useEffect(()=> console.log('this is matpros ', matpros), [matpros]);

    useEffect(()=> {
        if(matpros.length > 0 && matchedpro.length > 0){
            const matchedProObjs = [];
            matchedpro.forEach(pro => {
                const temparr = [];
                matpros.forEach(mat => {
                    if(pro.includes(mat._id)){
                        temparr.push(mat);
                    }
                });
                matchedProObjs.push(temparr)
            });
            // console.log(matchedProObjs);
            setMatchedobj(matchedProObjs);
        }
    }, [matpros, matchedpro])

  return (
    <div className='main-matched'>
        <p className="mb-4 body-font-bold fs-4">Matched Profiles</p>
         <div className="row justify-content-between">
            {
                matchedobj?.map((obj, ind) => (
                    obj.length > 1 && (
                        <div key={ind} className="col-md-6 mb-4">
                            <div className="p-3 border border-brand-pink bg-brand-light-pink rounded-brand">
                                <div className="matched-card d-flex align-items-center justify-content-between">
                                    <Link to={`/user/${obj[0].randomid}`} className="nav-link user-1">
                                        <div className="matched-card-img rounded-brand d-flex align-items-end overflow-hidden pt-5" style={{backgroundImage: `url('https://azdawaj.com/uploads/${obj[0]?.profileimage}')`}}>
                                            <p className="matched-name mb-0 text-white body-font-bold text-capitalize text-center p-2 w-100 lh-sm">
                                                {obj[0].firstname} {obj[0].lastname}
                                            </p>
                                        </div>
                                    </Link>
                                    <div className="heart-div d-flex p-0">
                                        <p className="mb-0"><i className="fas fa-heart text-brand-pink heart-1"></i></p>
                                        <p className="mb-0"><i className="fas fa-heart text heart-2"></i></p>
                                    </div>
                                    <Link to={`/user/${obj[1].randomid}`} className="nav-link user-2">
                                        <div className="matched-card-img rounded-brand d-flex align-items-end overflow-hidden pt-5" style={{backgroundImage: `url('https://azdawaj.com/uploads/${obj[1]?.profileimage}')`}}>
                                            <p className="matched-name mb-0 text-white body-font-bold text-capitalize text-center p-2 w-100 lh-sm">
                                                {obj[1].firstname} {obj[1].lastname}
                                            </p>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                ))
            }
         </div>
    </div>
  )
}

export default Matched;