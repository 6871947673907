import React, { useEffect, useState } from 'react';
import './Dashstats.css';
import { BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Dashstats() {

    const { fildate, setMalepros, setFemalepros, setMatchedpros } = useAuth();
    const navigate = useNavigate();
    const [allpro, setAllpro] = useState([]);
    const [malepro, setMalepro] = useState([]);
    const [femalepro, setFemalepro] = useState([]);
    const [matchedpro, setMatchedpro] = useState([]);
    const [thisyear, setThisyear] = useState([]);
    const [visiters, setVisiters] = useState([]);
    const [temallpro, setTempallpro] = useState([]);
    // const [filteredpros, setFilteredpros] = useState([]);

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(!localuser || localuser.length < 1){
            navigate('/admin/');
        }
    }, [navigate])

    useEffect(()=> {
        fetch(`https://azdawaj.com`).then(res => res.json()).then(data => {
            setAllpro(data);
            setTempallpro(data);
        }).catch(err => console.log('error occured while getting all the users', err));
    }, []);

    useEffect(()=> {
        const malepros = temallpro.filter(pro => pro.gender === 'Male');
        setMalepro(malepros);
        setMalepros(malepros);
        const femalepros = temallpro.filter(pro => pro.gender === 'Female');
        setFemalepro(femalepros);
        setFemalepros(femalepros);
        // let matched = [];
        // allpro.forEach(pro => {
        //     if(pro.reqaccepted && pro.reqaccepted.length > 0){
        //         matched.push(...pro.reqaccepted);
        //     }
        //     if(pro.reqstatus && pro.reqstatus.length > 0){
        //         pro.reqstatus.forEach(stat => {
        //             if(stat.status === 'accepted'){
        //                 // console.log(stat.rid)
        //                 matched.push(stat.rid)
        //             }
        //         })
        //     }
        // });
        // const uniqmatches = [...new Set(matched)];
        // // console.log(uniqmatches)
        // setMatchedpro(uniqmatches)
    }, [temallpro, setMalepros, setFemalepros])

    useEffect(()=> {
        const accArr = [];
        const matchedarr = [];
        temallpro.forEach((pro, ind) => {
            const matcharr = []
            if(pro.reqaccepted && pro.reqaccepted.length > 0){
                pro.reqaccepted.forEach(aid => {
                    matcharr.push([pro._id, aid]);
                    matchedarr.push([pro._id, aid])
                })
                accArr.push({id: pro._id, arr: pro.reqaccepted, matches: matcharr});
            };

            if(pro.reqstatus && pro.reqstatus.length > 0){
                const statusArr = [];
                pro.reqstatus.forEach(e => {
                    if(e.status === 'accepted'){
                        matchedarr.push([pro._id, e.rid]);
                        statusArr.push([pro._id, e.rid]);
                    }
                })
                // console.log('this is the statusarr', statusArr);
            }
        });
        // console.log(accArr);
        // console.log(matchedarr);
        const uniqueMatched = new Set(matchedarr.map(arr => JSON.stringify(arr)));
        const uniqueMatchedArr = Array.from(uniqueMatched).map(arr => JSON.parse(arr));
        // console.log(uniqueMatchedArr);
        setMatchedpro(uniqueMatchedArr);
        setMatchedpros(uniqueMatchedArr);
    }, [temallpro, setMatchedpros])

    const dateConverter = (dt) => {
        const date = new Date(dt);
        if(isNaN(date.getTime())){
            return 'invalid date or time'
        }
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        };
        return new Intl.DateTimeFormat('en-us', options).format(date);
    };

    useEffect(()=> {
        fetch(`https://azdawaj.com/api/visiters`).then(res => res.json()).then(data => {
            // console.log(data);
            setVisiters(data.visiters);
        }).catch(err => console.log('error occured while getting all the visiters ', err));
    }, [])

    useEffect(()=> {
        const graphData = [];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        // console.log(dateConverter(allpro[0]?.subdate));
        // const yearFilter = allpro.filter(e => dateConverter(e.subdate).toLowerCase().includes('2024'));
        // const yearVisiter = visiters?.filter(e => e.date.includes('2024'));
        const yearFilter = allpro
        const yearVisiter = visiters
        months.forEach((mon, ind) => {
            const someArr = []
            const vistArr = []
            yearFilter.forEach(yf => {
                if(dateConverter(yf.subdate).toLowerCase().includes(mon.toLowerCase())){
                    someArr.push(yf);
                    yearVisiter.forEach(vis => {
                        if(parseInt(vis.date.split('/')[0]) === ind + 1){
                            if(!vistArr.includes(vis)){
                                vistArr.push(vis)
                            }
                        }
                    })
                }
            });
            if(someArr.length > 0){
                graphData.push({name: mon, Signup: someArr.length, Visits: vistArr.length});
            }
        });
        // console.log(graphData);
        setThisyear(graphData);
    }, [allpro, visiters])

    // useEffect(() => console.log(thisyear), [thisyear]);

    const data = [
        {name: 'January', Signup: 400, Visits: 2400, amt: 2400},
        {name: 'Fabruary', Signup: 500, Visits: 2200, amt: 2400},
        {name: 'March', Signup: 100, Visits: 500, amt: 2400},
        {name: 'April', Signup: 900, Visits: 900, amt: 2400},
        {name: 'May', Signup: 200, Visits: 700, amt: 2400},
        {name: 'June', Signup: 300, Visits: 700, amt: 2400},
        {name: 'July', Signup: 298, Visits: 890, amt: 2400}
    ]

    const data2 = [
        {name: 'Page A', uv: 400, pv: 2400, cv: 150, vv: 1100, amt: 2400},
        {name: 'Page B', uv: 500, pv: 2200, cv: 110, vv: 290, amt: 2400},
        {name: 'Page C', uv: 100, pv: 500, cv: 900, vv: 370, amt: 2400},
        {name: 'Page D', uv: 900, pv: 900, cv: 1600, vv: 1500, amt: 2400},
        {name: 'Page E', uv: 200, pv: 700, cv: 240, vv: 200, amt: 2400}
    ];

    useEffect(()=> {
        // console.log(fildate)
        const dateoptioins = { 
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        }
        // console.log('this is filter date for ya ', fildate);
        const dateconverterStr = (d) => {
            const rd = new Date(d).toLocaleDateString('en-US', dateoptioins);
            return rd
        }
        const dateconverterObj = (d) => {
            const rd = new Date(d);
            return rd
        }
        if(fildate !== '' && fildate?.length > 0 && fildate.toLowerCase() !== 'all time'){
            if(fildate.includes('-')){
                const twodates = fildate.split('-');
                const startdate = new Date(twodates[0]);
                const enddate = new Date(twodates[1]);
                const twodateFilter = allpro.filter(e => dateconverterObj(e.subdate) >= startdate && dateconverterObj(e.subdate) <= enddate);
                // console.log('thlis is the firest filter ', twodateFilter);
                setTempallpro(twodateFilter);
            }else if(!fildate.includes('-')){
                const fildatePro = allpro.filter(e => dateconverterStr(e.subdate) === fildate);
                // console.log('this is the filter ', fildatePro);
                setTempallpro(fildatePro);
            }
        }else if(fildate.toLowerCase() === 'all time'){
            setTempallpro(allpro)
        }else{
            console.log('thsi is something wierd than you Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi error excepturi similique eaque, asperiores quae tempore? Iste ullam nemo quidem sed quos, illum possimus corporis consequatur vero dignissimos quas dolorem!')
        }
    }, [fildate, allpro]);

    // useEffect(()=> setAllpro(filterproarr), [filterproarr])
    // useEffect(()=> setAllpro(temallpro), [temallpro])

  return (
    <div className='main-dashboard'>
        <div className="dashboard">
            <p className="mb-4 fs-4 body-font-bold">Dashboard</p>
            <div className="row">
                <div className="col-md-3 col-6 mb-4">
                    <Link to='/admin/profiles/all' className="nav-link rounded-brand-sm bg-brand-light-pink p-3 py-3">
                        <p className="mb-0 fs-1 numbers body-font-bold">{temallpro?.length} <span className="text-muted body-font-semibold fs-6">Profiles</span></p>
                        <p className="text-muted body-font-bold text fs-4 mb-0"><i className="fas fa-equals me-1"></i> Total</p>
                    </Link>
                </div>
                <div className="col-md-3 col-6 mb-4">
                    <Link to='/admin/profiles/male' className="nav-link rounded-brand-sm bg-brand-light-pink p-3 py-3">
                        <p className="mb-0 fs-1 numbers body-font-bold">{malepro?.length} <span className="text-muted body-font-semibold fs-6">Profiles</span></p>
                        <p className="text-muted body-font-bold text fs-4 mb-0"><i className="fas fa-mars me-1"></i> Male</p>
                    </Link>
                </div>
                <div className="col-md-3 col-6 mb-4">
                    <Link to='/admin/profiles/female' className="nav-link rounded-brand-sm bg-brand-light-pink p-3 py-3">
                        <p className="mb-0 fs-1 numbers body-font-bold">{femalepro?.length} <span className="text-muted body-font-semibold fs-6">Profiles</span></p>
                        <p className="text-muted body-font-bold text fs-4 mb-0"><i className="fas fa-venus me-1"></i> Female</p>
                    </Link>
                </div>
                <div className="col-md-3 col-6 mb-4">
                    <Link to='/admin/matched' className="nav-link rounded-brand-sm bg-brand-light-pink p-3 py-3">
                        <p className="mb-0 fs-1 numbers body-font-bold">{matchedpro?.length} <span className="text-muted body-font-semibold fs-6">Profiles</span></p>
                        <p className="text-muted body-font-bold text fs-4 mb-0"><i className="fas fa-clone me-1"></i> Matched</p>
                    </Link>
                </div>
            </div>
            <div className="row graph-wrap mt-4 ps-0 ms-0">
                <div className="col-md-6 mb-4 px-0">
                    {
                        thisyear.length > 0? (
                            <ResponsiveContainer width='100%' height={300}>
                                <BarChart data={thisyear}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tick={{fontSize: '12px'}} />
                                    <YAxis tick={{fontSize: '10px'}} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Visits" fill="#fcc2ff" />
                                    <Bar dataKey="Signup" fill="#D862BC" />
                                </BarChart>
                            </ResponsiveContainer>
                        ):(
                            <ResponsiveContainer width='100%' height={300}>
                                <BarChart data={data}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" tick={{fontSize: '12px'}} />
                                    <YAxis tick={{fontSize: '10px'}} />
                                    <Tooltip />
                                    <Legend />
                                    <Bar dataKey="Visits" fill="#fcc2ff" />
                                    <Bar dataKey="Signup" fill="#D862BC" />
                                </BarChart>
                            </ResponsiveContainer>
                        )
                    }
                </div>
                <div className="col-md-6 mb-4 px-0">
                    <ResponsiveContainer width='100%' height={300}>
                        <LineChart data={data2}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tick={{fontSize: '12px'}} />
                            <YAxis tick={{fontSize: '10px'}} />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="pv" stroke="#fcc2ff" />
                            <Line type="monotone" dataKey="uv" stroke="#D862BC" />
                            <Line type="monotone" dataKey="cv" stroke="#ffb31e" />
                            <Line type="monotone" dataKey="vv" stroke="#8644A2" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashstats;