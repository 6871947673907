import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Navbar() {

    const {reqcount} = useAuth();
    const location = useLocation();
    const [dispnav, setDispnav] = useState(true);

    // useEffect(()=> console.log(reqcount), [reqcount]);
    
    const activeTabFunc = (path) => {
        return location.pathname === path && 'active-mob-tab';
    }

    useEffect(()=>{
        if(location.pathname.includes('user') || location.pathname.includes('signup') || location.pathname.includes('admin') || location.pathname.includes('editprofile')){
            setDispnav(false);
        }else{
            setDispnav(true)
        }
    },[location])

  return (
    <div className={`main-navbar-wrap ${!dispnav && 'd-none'}`}>
        <div className="navbar-wrap p-2 ps-3">
            <div className="navbar-container d-flex justify-content-between align-items-center">
                <div className="nav-logo-wrap">
                    {/* <h1 className="fs-4 mb-0 body-font-extrabold text-brand-pink">Shiya Wedding</h1> */}
                    <Link to='/' className='nav-link m-0 p-0'><img src="../assets/logo.webp" alt="logo" width='100px' className="img-fluid" /></Link>
                </div>
                <div className="nav-ul-wrap">
                    <ul className="p-0 m-0 d-flex align-items-center">
                        <li className="nav-li mb-0 me-5"><Link to={'/'} className='nav-link mb-0 body-font-medium'>HOME</Link></li>
                        <li className="nav-li mb-0 me-5"><Link to={'/explore'} className='nav-link mb-0 body-font-medium'>EXPLORE</Link></li>
                        <li className="nav-li mb-0 me-5"><Link to={'/saved'} className='nav-link mb-0 body-font-medium'>SAVED</Link></li>
                        <li className="nav-li mb-0 me-0"><Link to={'/profile'} className='nav-link mb-0 body-font-medium'>PROFILE</Link></li>
                    </ul>
                </div>
                <div className="mob-ul-wrap bg-white w-100">
                    <div className="mob-ul d-flex align-items-center justify-content-between p-3">
                        <Link to={'/'} className={`nav-link mob-nav-tab text-center d-flex aling-items-center p-2 px-3 rounded-pill ${activeTabFunc('/')}`}>
                            <i className="fas fa-home mb-0 fs-5 me-2 text-brand-pink"></i>
                            <p className="mb-0 nav-tab-text body-font-semibold text-brand-pink" style={{display: activeTabFunc('/')? 'block': 'none'}}>Home</p>
                        </Link>
                        <Link to={'/explore'} className={`nav-link mob-nav-tab text-center d-flex aling-items-center p-2 px-3 rounded-pill ${activeTabFunc('/explore')}`}>
                            <i className="far fa-compass mb-0 fs-5 me-2 text-brand-pink"></i>
                            <p className="mb-0 nav-tab-text body-font-semibold text-brand-pink" style={{display: activeTabFunc('/explore')? 'block': 'none'}}>Explore</p>
                        </Link>
                        <Link to={'/saved'} className={`nav-link mob-nav-tab text-center d-flex aling-items-center p-2 px-3 rounded-pill ${activeTabFunc('/saved')}`}>
                            <i className="fas fa-bookmark mb-0 fs-5 me-2 text-brand-pink"></i>
                            <p className="mb-0 nav-tab-text body-font-semibold text-brand-pink" style={{display: activeTabFunc('/saved')? 'block': 'none'}}>Saved</p>
                        </Link>
                        <Link to={'/profile'} className={`nav-link mob-nav-tab text-center d-flex aling-items-center p-2 px-3 rounded-pill ${activeTabFunc('/profile')}`}>
                            <i className="fas fa-user mb-0 fs-5 me-2 text-brand-pink"></i>
                            <p className="mb-0 nav-tab-text body-font-semibold text-brand-pink" style={{display: activeTabFunc('/profile')? 'block': 'none'}}>Profile</p>
                        </Link>
                    </div>
                </div>
                <div className="nav-btn-wrap d-flex align-items-center">
                    <Link to='/requests' data-count={reqcount > 99? '99+': reqcount} className={`body-font-medium ${reqcount > 0 && 'nav-req'} rounded-pill text-brand-pink fs-5 pe-2`}><i className="fas fa-user-plus"></i></Link>
                    <Link to='/requests' className='body-font-medium rounded-pill text-brand-pink fs-5 px-3'><i className="fas fa-comment"></i></Link>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Navbar;