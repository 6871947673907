import React, { useEffect, useState } from 'react';
import './Delreqs.css';
import Listingcard from '../../Components/Listingcard/Listingcard';
import { useAuth } from '../../Context/Authcontext';

function Delreqs() {

    const { decodeJSON } = useAuth();
    const [allreqproid, setAllreqproid] = useState([]);
    const [allreqpro, setAllreqpro] = useState([]);
    const [admin, setAdmin] = useState({});

    useEffect(()=> {
        fetch(`https://azdawaj.com/api/getalldelreqs`).then(res => res.json()).then(data => setAllreqproid(data)).catch(err => {
            console.log('error occured while getting all the requests ', err);
        })
    }, []);

    useEffect(()=> {
        const allids = allreqproid.map(e => e.id);
        const uniqProid = [...new Set(allids)]
        // console.log(uniqProid, allids)
        Promise.all(uniqProid.map(id => fetch(`https://azdawaj.com/api/profile/${id}`).then(res => res.json()))).then(data => {
            console.log(data);
            setAllreqpro(data);
        }).catch(err => console.log('error occured while getting all the profiles ', err));
    }, [allreqproid]);

    useEffect(()=> {
        const adlocprodat = localStorage.getItem("adlocprodat");
        if(adlocprodat){
            setAdmin(decodeJSON(adlocprodat));
        }
    }, [decodeJSON]);

  return (
    <div className='main-verificationrequests'>
        <p className="fs-4 body-font-bold">Deletion Requests</p>
        <div className="profiles-wrap row p-0">
            {
                allreqpro.length > 0? (
                    allreqpro.map((pro, ind) => (
                        <div key={ind} className="col-md-4 mb-3">
                            <Listingcard userid={pro.randomid} num={99} verified={pro.verified} reqs={pro.reqreceived} createdby={pro.createdfor} lastseen={pro.lastseen} admin={admin.usertype} email={pro.email} pass={pro.password} blur={pro.profileblur} img={`https://azdawaj.com/uploads/${pro?.profileimage}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                        </div>
                    ))
                ): (
                    <p className="mb-0 text-muted text-center mt-5 fs-5 text-muted">No Requests</p>
                )
            }
        </div>
    </div>
  )
}

export default Delreqs;