import React, { useState, useEffect } from 'react';
import './Createadmin.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Createadmin() {

    const { decodeJSON } = useAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(!localuser || localuser.length < 1){
            navigate('/admin/');
        }
    }, [navigate])

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(localuser && localuser.length > 0){
            const declocaluser = decodeJSON(localuser);
            if(declocaluser.usertype !== 'superadmin'){
                navigate('/admin/profiles');
                // console.log(declocaluser.usertype)
            }
        }
    }, [navigate, decodeJSON])

    const createUserFunc = () => {
        fetch('https://azdawaj.com/api/createsuperuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"username": username, "password": password, "usertype": 'admin'})
        }).then(res => res.json()).then(data => {
            // console.log(data);
            if(data.success){
                navigate('/admin/admins')
            }
        }).catch(err => console.log('error occured while creating admin ', err));
    }

  return (
    <div className='main-createadmin'>
        <div className="create-admin-wrapper pt-5 mb-5">
            <div className="create-admin-pop mx-auto shadow rounded-brand mt-5 p-3 py-4">
                <input type="text" name="username" id="username" className='form-control shadow-none outline-none border rounded-3 mb-3 p-2 px-3' placeholder='Enter user name' value={username} onChange={(e)=> setUsername(e.target.value)}/>
                <input type="password" name="username" id="password" className='form-control shadow-none outline-none border rounded-3 mb-3 p-2 px-3' placeholder='Create a strong password' value={password} onChange={(e)=> setPassword(e.target.value)}/>
                <button className="create-admin-btn btn btn-brand-pink rounded-3 p-2 px-3" onClick={createUserFunc}>CREATE ADMIN</button>
            </div>
        </div>
    </div>
  )
}

export default Createadmin;