import React, { useEffect, useState } from 'react';
import './Alladmins.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Alladmins() {

    const { decodeJSON } = useAuth();
    const navigate = useNavigate();
    const [superusers, setSuperusers] = useState([]);
    const [editpop, setEditpop] = useState('none');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [userid, setUserid] = useState('');

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(!localuser || localuser.length < 1){
            navigate('/admin/');
        }
    }, [navigate])

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(localuser && localuser.length > 0){
            const declocaluser = decodeJSON(localuser);
            if(declocaluser.usertype !== 'superadmin'){
                navigate('/admin/dashboard');
                // console.log(declocaluser.usertype)
            }
        }
    }, [navigate, decodeJSON])

    useEffect(()=> {
        fetch('https://azdawaj.com/api/getsuperusers').then(res => res.json()).then(data => {
            // console.log(data);
            setSuperusers(data);
        }).catch(err => console.log('error occured while getting all the super users ', err));
    }, []);

    const deleteAdmin = (id) => {
        fetch(`https://azdawaj.com/api/deletesuperuser/${id}`, {method: 'DELETE'}).then(res => res.json()).then(data => {
            data.success ? setSuperusers(superusers.filter(user => user._id !== id)) : console.log('success is not true ', data);
        }).catch(err => console.log("error occured while deleteting the superuser ", err));
    }

    const editPop = (id, user, pass) => {
        setEditpop();
        setUsername(user);
        setPassword(pass);
        setUserid(id);
    };

    // useEffect(()=> console.log(userid, username, password), [userid, username, password])

    const editAdmin = () => {
        fetch(`https://azdawaj.com/api/editsuperuser`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({"id": userid, "username": username, "password": password})
        }).then(res => res.json()).then(data => {
            if(data.username){
                setEditpop('none');
                for(let user of superusers){
                    if(user._id === userid){
                        user.username = username;
                        user.password = password;
                    }
                }
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while updating the admin ', err))
    }

  return (
    <div className='main-alladmins p-3'>
        <div className="row p-0">
        {
            superusers.map((user, ind)=> (
                <div key={ind} className="col-md-4 px-3">
                    <div className="mb-3 rounded-brand-sm row justify-content-between bg-brand-pink py-3 px-2">
                        {/* <p className="col-md-2 d-none mb-0 body-font-medium text-white">{ind + 1}</p> */}
                        <p className="col-4 mb-0 body-font-medium text-white">{user.username}</p>
                        <p className="col-4 mb-0 body-font-medium text-white">{user.password}</p>
                        {
                            user.usertype !== 'superadmin'? (
                                <div className="col-2 d-flex justify-content-end">
                                    <p className="mb-0 body-font-medium text-white me-3 cursor-pointer" onClick={()=> editPop(user._id, user.username, user.password)}><i className="fas fa-pen"></i></p>
                                    <p className="mb-0 body-font-medium text-danger cursor-pointer" onClick={()=> deleteAdmin(user._id)}><i className="fas fa-trash-alt"></i></p>
                                </div>
                            ): ''
                        }
                    </div>
                </div>
            ))
        }
        </div>
        <div className="edit-pop-wrap" style={{display: editpop}}>
            <div className="edit-pop">
                <p className="w-max-content fs-2 mb-0 text-white ms-auto cursor-pointer" onClick={()=> setEditpop('none')}>&times;</p>
            </div>
            <div className="edit-pop p-3 py-4 rounded-brand bg-white">
                <input type="text" name="username" id="username" className='form-control shadow-none outline-none border rounded-3 p-2 px-3 mb-3' placeholder='Username here' value={username} onChange={(e)=> setUsername(e.target.value)}/>
                <input type="password" name="password" id="password" className='form-control shadow-none outline-none border rounded-3 p-2 px-3 mb-3' placeholder='Password here' value={password} onChange={(e)=> setPassword(e.target.value)}/>
                <button className='btn btn-brand-pink rounded-3 p-2 px-3 text-uppercase' onClick={editAdmin}>Update admin</button>
            </div>
        </div>
    </div>
  )
}

export default Alladmins;