import React, { useCallback, useEffect, useState } from 'react';
import './Listingcard.css';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Listingcard(props) {

    const { userid } = useAuth();
    const { encodeJSON } = useAuth();
    const { login } = useAuth();
    const { acceptedreqs } = useAuth();
    const { verifiedIcon } = useAuth();
    const location = useLocation();
    const [isreq, setIsreq] = useState(false);
    const [isacce, setIsacce] = useState(false);
    const [admin, setAdmin] = useState('');
    const [isadmin, setIsadmin] = useState(false);
    const [lastseen, setLastseen] = useState('');

    useEffect(()=>{
        if(location.pathname.includes('requests')){
            setIsreq(true);
        }else{
            setIsreq(false);
        }
    },[location]);

    useEffect(()=> {
        const adlocprodat = localStorage.getItem('adlocprodat');
        if(adlocprodat && adlocprodat.length > 0){
            setIsadmin(true);
        }
    }, [])

    const ageCalculator = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const acceptFunc = () => {
        fetch(`https://azdawaj.com/api/acceptrequest/${userid}/${props.sid}`).then(res => res.json()).then(data => {
            // console.log(data);
            localStorage.setItem('locpordat', encodeJSON(data.prodat));
            login();
        }).catch(err => console.log('error occured while sending the acceptance ', err));
    }

    const rejectFunc = () => {
        fetch(`https://azdawaj.com/api/rejectrequest/${userid}/${props.sid}`).then(res => res.json()).then(data => {
            // console.log(data);
            // console.log(data);
            localStorage.setItem('locpordat', encodeJSON(data.prodat));
            login();
        }).catch(err => console.log('error occured while sending the acceptance ', err));
    }

    const isacceCB = useCallback((bool)=> {
        setIsacce(bool);
    }, [setIsacce]);

    useEffect(()=> {
        // console.log(acceptedreqs);
        if(acceptedreqs?.includes(props.sid)){
            isacceCB(true);
        }else{
            isacceCB(false);
        }
    }, [acceptedreqs, isacceCB, props])

    useEffect(()=> {
        if(props.admin){
            setAdmin(props.admin);
        }
    }, [props.admin])

    const createdByFunc = (by)=> {
        if(by === 'Myself'){
            return 'Myself'
        }else if(by === 'Admin'){
            return 'Admin'
        }else if(by === 'Son' || by === 'Daughter'){
            return 'Parent'
        }else if(by === 'Sister' || by === 'Bother'){
            return 'Sibling'
        }else if(by === 'Relative'){
            return 'Relative'
        }
    };

    useEffect(()=> {
        if(props.lastseen){
            const date = new Date(props.lastseen);
            const todaydate = new Date();
            const difference = todaydate - date;

            const diffseco = Math.floor(difference / 1000)
            const diffminu = Math.floor(diffseco / 60)
            const diffhour = Math.floor(diffminu / 60)
            const diffdays = Math.floor(diffhour / 24)
            // console.log(diffseco, diffminu, diffhour, diffdays);
            setLastseen((diffdays > 0 && `${diffdays}d`) || (diffhour > 0 && `${diffhour}h`) || (diffminu > 0 && `${diffminu}m`) || (diffseco > 0 && `${diffseco}s`));
            // console.log((diffdays > 0 && `${diffdays}d`) || (diffhour > 0 && `${diffhour}h`) || (diffminu > 0 && `${diffminu}m`) || (diffseco > 0 && `${diffseco}s`))
        }
        // else{
        //     console.log('no date')
        // }
    }, [props.lastseen])

  return (
    <div className='main-listingcard'>
        <Link to={`/user/${props.userid}`} className='nav-link m-0 p-0'>
            <div className="listing-card rounded-brand p-2 bg-brand-light-pink d-flex">
                <div className={`listing-card-img overflow-hidden rounded-brand ${props.blur? 'temp-class': ''}`}>
                    <img src={props?.img} alt="card proifle" className={`img-fluid w-100`} />
                    {/* <img src={props?.img} alt="" className={`img-fluid temp-class w-100`} /> */}
                </div>
                <div className="listing-card-data d-flex flex-column justify-content-between py-1 pe-2 ps-3">
                    <div className="nameNprofession">
                        {/* <i className={`fas fa-check-circle text-brand-pink ms-1 ${!props.verified && 'd-none'}`}></i> */}
                        {
                            isacce || isadmin? (
                                <p className="mb-0 listing-card-name body-font-bold fs-5 text-capitalize">{props.firstname} {props.lastname} <img src={verifiedIcon} alt="icon" className={`verified-img ms-1 ${!props.verified && 'd-none'}`} /></p>
                            ): (
                                <p className="mb-0 listing-card-name body-font-bold fs-5 text-capitalize">{props?.firstname?.length > 0 ?Array.from(props?.firstname)?.splice(0, 3).join(''): ''}<sub className='body-font-bold'>****</sub> <img src={verifiedIcon} alt="icon" className={`verified-img ms-1 ${!props.verified && 'd-none'}`} /></p>
                            )
                        }
                        <p className="mb-0 listing-card-prof body-font-bold text-muted text-uppercase mt-1">{props.proffessoin}</p>
                    </div>
                    {
                        admin?(
                            admin === 'superadmin' || admin === 'admin'? (
                                <div className="ageNcity">
                                    <p className="mb-1 listing-card-age body-font-medium text-muted text-break">Requests: <span className="body-font-bold text-muted">{props?.reqs?.length}</span></p>
                                    <p className="mb-0 listing-card-age body-font-medium text-muted text-break"><i className="fas fa-lock text-brand-pink me-2 d-inline"></i>{props.email}</p>
                                    <p className="mb-0 listing-card-city body-font-medium text-muted"><i className="fas fa-key text-brand-pink me-2"></i>{props.pass}</p>
                                </div>
                            ): (
                                <div className="ageNcity">
                                    <p className="mb-0 listing-card-age body-font-medium text-muted text-capitalize"><i className="fas fa-calendar-check text-brand-pink me-2"></i>{ageCalculator(props.age)} YEARS</p>
                                    <p className="mb-0 listing-card-city body-font-medium text-muted text-capitalize"><i className="fas fa-map-marker-alt text-brand-pink me-2"></i>{props.location}</p>
                                </div>
                            )
                        ):(
                            <div className="ageNcity">
                                <p className="mb-0 listing-card-age body-font-medium text-muted text-capitalize"><i className="fas fa-calendar-check text-brand-pink me-2"></i>{ageCalculator(props.age)} YEARS</p>
                                <p className="mb-0 listing-card-city body-font-medium text-muted text-capitalize"><i className="fas fa-map-marker-alt text-brand-pink me-2"></i>{props.location}</p>
                            </div>
                        )
                    }
                </div>
            </div>
        </Link>
        {
            props.num === 0 && (
                <div className={`request-btn-wrap p-2 m-2 mt-0 px-4 row ${isreq? 'd-flex': 'd-none'}`}>
                    <div className="col-6 p-1">
                        <p className="mb-0 accept-btn btn rounded-pill body-font-semibold w-100 text-center" onClick={acceptFunc}><i className="fas fa-check me-2"></i> Accept</p>
                    </div>
                    <div className="col-6 p-1">
                        <p  className="mb-0 decline-btn btn rounded-pill body-font-semibold w-100 text-center" onClick={rejectFunc}><i className="fas fa-times me-2"></i> Decline</p>
                    </div>
                </div>
            )
        }
        {
            props.num === 1 && (
                <div className={`request-btn-wrap p-2 m-2 mt-0 px-4 row ${isreq? 'd-flex': 'd-none'}`}>
                    <div className="col-12 p-1">
                        {
                            props.status === 'accepted' && (
                                <p className="mb-0 accept-btn btn rounded-pill body-font-semibold w-100 text-center text-capitalize"><i className="fas fa-check me-2"></i> {props.status}</p>
                            )
                        }
                        {
                            props.status === 'rejected' && (
                                <p  className="mb-0 decline-btn btn rounded-pill body-font-semibold w-100 text-center text-capitalize"><i className="fas fa-times me-2"></i> {props.status}</p>
                            )
                        }
                        {
                            props.status === 'pending' && (
                                <p className="mb-0 pending-btn btn rounded-pill body-font-semibold w-100 text-center text-capitalize"><i className="far fa-clock me-2"></i> {props.status}</p>
                            )
                        }
                    </div>
                </div>
            )
        }
        {
            props.num === 99 && (
                <div className={`request-btn-wrap p-2 m-2 mt-0 px-3 d-flex justify-content-between align-items-center`}>
                    <div className="created-by">
                        <p className="mb-0 text-muted card-out-info">Created by: <span className="text-muted body-font-bold">{createdByFunc(props.createdby)}</span></p>
                    </div>
                    <div className="created-by">
                        <p className="mb-0 text-muted card-out-info">Active <span className="text-muted body-font-bold">{lastseen? `${lastseen} ago`: '10m ago'}</span></p>
                    </div>
                </div>
            )
        }
    </div>
  )
}

export default Listingcard