import React, { useEffect } from 'react';
import './Terms.css';
import { Helmet } from 'react-helmet';

function Terms() {

    useEffect(()=> window.scrollTo(0,0), []);

    const canonicalurl = window.location.href;

  return (
    <div className='main-terms'>
        <Helmet>
            <link rel="canonical" href={canonicalurl} />
        </Helmet>
        <div className="container mt-4">
            <h2 className="fs-4 body-font-bold text-center">Terms & Conditions</h2>
            <p className="text-center mb-3">
                By using this site (Azdawaj.com), you are accepting to our Terms & Conditions.
            </p>
            <div className="full-content mt-5">
                <p className="mb-3">
                    Welcome to Azdawaj.com, a matrimonial platform for the Shia community. By accessing or using our services, you agree to the following terms and conditions.
                </p>
                <ol className='terms-ol'>
                    <li>
                        <p className="mb-0 body-font-semibold">Eligibility.</p>
                        <p className="mb-4">You must be at least 18 years old and of legal age to marry according to the laws of your country to use this platform.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">User Responsibilities</p>
                        <p className="mb-4">You agree to provide accurate and truthful information when creating your profile.
                        You are responsible for maintaining the confidentiality of your account details. <br />
                        Misuse of the platform, including harassment, providing false information, or inappropriate behavior, is strictly prohibited and may lead to account suspension or termination.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Profile Matching and Communication</p>
                        <p className="mb-4">Users can send and accept requests from other users to match. Once a match is made, Azdawaj will arrange a Google Meet between both parties and their guardians. <br />
                        Azdawaj does not offer a chat feature. Any interaction between matched users must take place in the arranged Google Meet.
                        By accepting a match, you agree to participate in the arranged Google Meet at the specified time.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Liability</p>
                        <p className="mb-4">Azdawaj is a platform to facilitate introductions but does not guarantee the accuracy, suitability, or behavior of its users. We are not liable for any actions, disputes, or consequences arising from user interactions, whether online or offline. Users are solely responsible for following our guidelines and ensuring their own safety and well-being.</p>
                    </li>
                    <li>
                        <p className="mb-0 body-font-semibold">Guidelines Compliance</p>
                        <p className="mb-4">Failure to comply with our guidelines may result in suspension or termination of your account. Azdawaj is not responsible for any issues arising from your failure to adhere to these guidelines.</p>
                    </li>
                </ol>
            </div>
        </div>
    </div>
  )
};

export default Terms;