import React, { useEffect, useState } from 'react';
import './Explore.css';
import { Link, useNavigate } from 'react-router-dom';
import Listingcard from '../../Components/Listingcard/Listingcard';
import Signuppop from '../../Components/Signuppop/Signuppop';
import { useAuth } from '../../Context/Authcontext';
import { Helmet } from 'react-helmet';

function Explore() {

    const navigate = useNavigate();
    const { islogged } = useAuth();
    const { profiledata } = useAuth();
    const [ishome, setIshome] = useState(false);
    const [searchpop, setSearchpop] = useState('none');
    const [profiles, setProfile] = useState([]);
    const [filpro, setFilpro] = useState([]);
    const [comfil, setComfil] = useState('All');
    const [statedrop, setStatedrop] = useState('none');
    const [citydrop, setCitydrop] = useState('none');
    const [state, setState] = useState('');
    const [searchjson, setSearchjson] = useState({
        text: '',
        gender: profiledata? profiledata?.gender === 'Male'? 'Female': 'Male': 'Female',
        country: '',
        city: '',
        maritalstatus: 'Never Married'
    });
    const allcities = [
        {
            state: "Andhra Pradesh",
            cities: ["Adoni", "Amalapuram", "Anantapur", "Atmakur", "Bhimavaram", "Bobbili", "Bapatla", "Chittoor", "Chilakaluripet", "Dharmavaram", "Darsi", "Dowleswaram", "Eluru", "Giddalur", "Gokavaram", "Gooty", "Gudivada", "Gudur", "Guntakal", "Guntur", "Hindupur", "Jaggayyapeta", "Kadapa", "Kakinada", "Kalyandurg", "Kandukur", "Kavali", "Kothapeta", "Kurnool", "Lepakshi", "Machilipatnam", "Madanapalle", "Macherla", "Mandapeta", "Mangalagiri", "Markapur", "Mydukuru", "Nagari", "Nandyal", "Narasaraopet", "Narsipatnam", "Nellore", "Nidadavole", "Nuzvid", "Ongole", "Palakollu", "Peddapuram", "Pithapuram", "Ponnur", "Proddatur", "Pulivendula", "Punganur", "Puttur", "Rajahmundry", "Rayachoti", "Rayadurg", "Repalle", "Salur", "Samalkot", "Sattenapalle", "Srikakulam", "Sullurupeta", "Suryaraopeta", "Tadipatri", "Tadepalligudem", "Tenali", "Tirupati", "Vinukonda", "Vijayawada", "Visakhapatnam", "Vizianagaram", "Yemmiganur"]
        },
        {
            state: "Arunachal Pradesh",
            cities: ["Along", "Anini", "Basar", "Bomdila", "Bhalukpong", "Changlang", "Daporijo", "Dirang", "Itanagar", "Jairampur", "Khonsa", "Koloriang", "Likabali", "Longding", "Namsai", "Pasighat", "Palin", "Roing", "Seppa", "Tawang", "Tezu", "Tirap", "Tuting", "Yingkiong", "Ziro"]
        },
        {
            state: "Assam",
            cities: ["Abhayapuri", "Amguri", "Azara", "Badarpur", "Barpeta", "Barpeta Road", "Bongaigaon", "Bokakhat", "Bokajan", "Bokolia", "Biswanath Chariali", "Charaideo", "Changsari", "Charaibari", "Chirang", "Chhaparmukh", "Dhekiajuli", "Dhubri", "Dibrugarh", "Diphu", "Duliajan", "Dergaon", "Doomdooma", "Goalpara", "Golaghat", "Gohpur", "Guwahati", "Gauripur", "Haflong", "Hailakandi", "Hajo", "Hatsingimari", "Hojai", "Howly", "Jagiroad", "Jorhat", "Jonai", "Kampur", "Karimganj", "Kokrajhar", "Lakhipur", "Lakhimpur", "Majuli", "Mangaldoi", "Margherita", "Mariani", "Moranhat", "Morigaon", "Nalbari", "Namrup", "Narayanpur", "Nazira", "Nagaon", "North Lakhimpur", "Pathsala", "Palasbari", "Rangia", "Sadiya", "Sibsagar", "Silchar", "Sonari", "Sorbhog", "Tinsukia", "Tezpur", "Titabar", "Tamulpur", "Udalguri", "West Karbi Anglong"]
        },
        {
            state: "Bihar",
            cities: ["Araria", "Arrah", "Aurangabad", "Bagaha", "Barauli", "Barh", "Begusarai", "Bhabua", "Bhagalpur", "Bihar Sharif", "Bikramganj", "Buxar", "Chhapra", "Darbhanga", "Daudnagar", "Dehri-on-Sone", "Dumraon", "Gaya", "Gopalganj", "Hajipur", "Hilsa", "Jamalpur", "Jamui", "Jehanabad", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhubani", "Mahnar Bazar", "Mairwa", "Maner", "Masaurhi", "Munger", "Motihari", "Motipur", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Phulwari Sharif", "Purnia", "Rajgir", "Raxaul", "Revilganj", "Rosera", "Saharsa", "Samastipur", "Sasaram", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Sonepur", "Supaul", "Udakishanganj", "Vaishali", "Warsaliganj"]
        },
        {
            state: "Chhattisgarh",
            cities: ["Ambikapur", "Akaltara", "Arang", "Baikunthpur", "Balod", "Baloda Bazar", "Balrampur", "Bemetara", "Bhanupratappur", "Bhilai", "Bijapur", "Bilaspur", "Birgaon", "Champa", "Chirmiri", "Charama", "Dalli-Rajhara", "Dantewada", "Dhamtari", "Durg", "Gaurella", "Gariaband", "Jagdalpur", "Janjgir", "Jashpur", "Jashpurnagar", "Kanker", "Kawardha", "Khairagarh", "Korba", "Kondagaon", "Koriya", "Mahasamund", "Manendragarh", "Mungeli", "Narayanpur", "Pandariya", "Pathalgaon", "Patan", "Pendra", "Phundhar", "Raigarh", "Raipur", "Rajnandgaon", "Ratanpur", "Sakti", "Saraipali", "Sarangarh", "Sukma", "Surajpur", "Takhatpur", "Tilda", "Umarkote"]
        },
        {
            state: "Goa",
            cities: ["Arambol", "Assagao", "Baga", "Bicholim", "Canacona", "Cansaulim", "Candolim", "Carambolim", "Chicalim", "Colva", "Cuncolim", "Curca", "Dharbandora", "Dabolim", "Dona Paula", "Mapusa", "Margao", "Mormugao", "Mollem", "Navelim", "Nuvem", "Panaji", "Pernem", "Ponda", "Porvorim", "Saligao", "Sanquelim", "Siolim", "Sanguem", "Seraulim", "Tivim", "Taleigao", "Valpoi", "Vasco da Gama", "Verna", "Vagator"]
        },
        {
            state: "Gujarat",
            cities: ["Ahmedabad", "Amreli", "Anand", "Anjar", "Ankleshwar", "Bardoli", "Bayad", "Bhachau", "Bhadran", "Bhavnagar", "Bhuj", "Botad", "Chhota Udaipur", "Chorvad", "Dahod", "Deesa", "Dhandhuka", "Dholka", "Dhoraji", "Dwarka", "Gandhidham", "Gandhinagar", "Godhra", "Gondal", "Halol", "Himatnagar", "Jamnagar", "Jetpur", "Junagadh", "Kalol", "Kapadvanj", "Karjan", "Keshod", "Khambhat", "Kheda", "Khedbrahma", "Lathi", "Lunawada", "Mahesana", "Mandvi", "Manavadar", "Mansa", "Modasa", "Morbi", "Muli", "Nadiad", "Navsari", "Palanpur", "Palitana", "Patan", "Porbandar", "Rajkot", "Rajpipla", "Rajula", "Sachin", "Salaya", "Sanand", "Savarkundla", "Siddhpur", "Sihor", "Songadh", "Surat", "Talaja", "Thangadh", "Tharad", "Umbergaon", "Unjha", "Vadodara", "Valsad", "Vapi", "Veraval", "Vijapur", "Viramgam", "Visnagar", "Wadhwan"]
        },
        {
            state: "Haryana",
            cities: ["Ambala", "Bahadurgarh", "Ballabgarh", "Bhiwani", "Barwala", "Charkhi Dadri", "Chandigarh", "Faridabad", "Fatehabad", "Gurgaon", "Gohana", "Hisar", "Hansi", "Hodal", "Jagadhri", "Jind", "Jhajjar", "Karnal", "Kaithal", "Kherki Daula", "Mahendragarh", "Mewat", "Meham", "Mohindergarh", "Narnaul", "Palwal", "Panipat", "Pataudi", "Rohtak", "Rewari", "Sirsa", "Sohna", "Sadulshahar", "Tohana", "Yamunanagar"]
        },
        {
            state: "Himachal Pradesh",
            cities: ["Arki", "Aru", "Anni", "Baddi", "Bilaspur", "Baijnath", "Banjar", "Bhuntar", "Bharari", "Bharmaur", "Chamba", "Chirgaon", "Chaupal", "Dharamshala", "Dalhousie", "Dehra", "Dhameta", "Dharampur", "Ghumarwin", "Gagret", "Gopalpur", "Hamirpur", "Hatu", "Jubbal", "Kangra", "Kullu", "Keylong", "Koti", "Kothi", "Mcleod Ganj", "Manali", "Lahaul", "Luhri", "Mandi", "Palampur", "Nagrota", "Nahan", "Nadaun", "Nirmand", "Parwanoo", "Pangna", "Padhar", "Rampur", "Rohru", "Renukoot", "Shimla", "Solan", "Sundernagar", "Sirmaur", "Sarahan", "Theog", "Tandi", "Tashigang", "Una", "Udaipur", "Wangtoo"]
        },
        {
            state: "Jharkhand",
            cities: ["Adityapur", "Aurangabad", "Amrapali", "Bokaro Steel City", "Bokaro", "Bagodar", "Bermo", "Baliapur", "Barhi", "Bundu", "Bahragora", "Chaibasa", "Chas", "Chandrapura", "Dhanbad", "Deoghar", "Daltonganj", "Dumka", "Dharampur", "Giridih", "Gumia", "Ghatshila", "Hazaribagh", "Jamshedpur", "Jamtara", "Jharia", "Koderma", "Kendrapara", "Kuchai", "Medininagar", "Madhupur", "Muri", "Malkera", "Nawadih", "Nagar", "Nala", "Patamda", "Palamu", "Pakur", "Pipla", "Ranchi", "Ramgarh", "Raghubar Nagar", "Ranishwar", "Singhbhum", "Seraikela", "Silli", "Sahibganj", "Tundi", "Tata Nagar", "Uchhama", "Vikramshila"]
        },
        {
            state: "Karnataka",
            cities: ["Alnavar", "Ambur", "Anekal", "Arsikere", "Bangalore", "Bagalkot", "Ballari", "Bantwal", "Basavanagudi", "Bidar", "Bijapur", "Bangalore Rural", "Bangalore Urban", "Bhatkal", "Bhadravati", "Channarayapatna", "Chikkamagalur", "Chitradurga", "Chikkaballapur", "Davanagere", "Devanahalli", "Dharwad", "Dandeli", "Gadag", "Gokak", "Gulbarga", "HubliDharwad", "Hassan", "Haveri", "Hospet", "Jog Falls", "Jayanagar", "Jnanabharathi", "Kolar", "Koppal", "Kundapur", "Karwar", "Kodagu", "Kolar Gold Fields", "Kakkanad", "Lakshmeshwar", "Mysore", "Mangalore", "Mandya", "Madhugiri", "Nanjangud", "Navalgund", "Puttur", "Raichur", "Ramanagara", "Ranebennur", "Shimoga", "Sira", "Sirsi", "Sakleshpur", "Tumkur", "Tiptur", "Udupi", "Ujire", "Vijayapura", "Vijayanagara", "Wadi"]
        },
        {
            state: "Kerala",
            cities: ["Alappuzha", "Angamaly", "Adoor", "Attingal", "Bharananganam", "Bharathapuzha", "Changanassery", "Cherthala", "Chirayinkeezhu", "Delhi", "Devikulam", "Ernakulam", "Ettumanoor", "Guruvayur", "Kollam", "Haripad", "Idukki", "Jnanpith", "Kochi", "Kottayam", "Kasaragod", "Kannur", "Kalpetta", "Kumarakom", "Kothamangalam", "Lakkidi", "Munnar", "Malappuram", "Muvattupuzha", "Mundakkayam", "Manjeri", "Nellikkunnu", "Nedumbassery", "Nattika", "Palakkad", "Ponnani", "Pattambi", "Punalur", "Perinthalmanna", "Qurankutty", "Ranni", "Ramankari", "Sreekandapuram", "Shoranur", "Sabarimala", "Thrissur", "Thiruvananthapuram", "Thodupuzha", "Uzhavoor", "Vatakara", "Vypin Island", "Wayanad"]
        },
        {
            state: "Madhya Pradesh",
            cities: ["Alirajpur", "Ashoknagar", "Anuppur", "Agar Malwa", "Bhopal", "Burhanpur", "Balaghat", "Banda", "Biaora", "Chhindwara", "Chhatarpur", "Chachoda", "Dewas", "Dhar", "Damoh", "Dindori", "Guna", "Gwalior", "Hoshangabad", "Harda", "Jabalpur", "Jhabua", "Jorhat", "Khandwa", "Katni", "Kota", "Khurai", "Lahar", "Lalitpur", "Mandsaur", "Mandla", "Morena", "Mhow", "Neemuch", "Narsinghpur", "Niwari", "Pachmarhi", "Panna", "Pithampur", "Raisen", "Raghogarh", "Ratlam", "Rajgarh", "Rewa", "Sagar", "Sihora", "Shivpuri", "Satna", "Tikamgarh", "Tori", "Ujjain", "Umaria", "Vidisha", "Waidhan"]
        },
        {
            state: "Maharashtra",
            cities: ["Aurangabad", "Amravati", "Ahmednagar", "Alibaug", "Akola", "Mumbai", "Bhiwandi", "Bhivpur", "Bhadravati", "Chandrapur", "Chinchwad", "Colaba", "Dadar", "Dombivli", "Durg", "Dhule", "Erandol", "Ellora", "Goa", "Gadchiroli", "Gondia", "Goregaon", "Hingoli", "Hamrapur", "Ichalkaranji", "Igatpuri", "Jalgaon", "Jalna", "Junnar", "Junagadh", "Kolhapur", "Kalyan", "Kasarvadavali", "Kandivali", "Kopargaon", "Karad", "Latur", "Lonavala", "Nagpur", "Nanded", "Nashik", "Malegaon", "Mira-Bhayandar", "Matheran", "Navi Mumbai", "Nandurbar", "Pune", "Panvel", "Pimpri-Chinchwad", "Pali", "Ratnagiri", "Raigad", "Raver", "Solapur", "Satara", "Sangli", "Shirdi", "Shivaji Nagar", "Thane", "Turbhe", "Tuljapur", "Ulhasnagar", "Udgir", "Vasai", "Vikhroli", "Vasai-Virar", "Wadi"]
        },
        {
            state: "Manipur",
            cities: ["Andro", "Airfield", "Bishnupur", "Bungmual", "Churachandpur", "Chandel", "Dimapur", "Dholak", "Hangkhal", "Hmar", "Imphal", "Irang", "Kakching", "Kangpokpi", "Kumbi", "Lamlai", "Langthabal", "Moreh", "Moirang", "Mao", "Matsang", "Ningthoukhong", "Nambol", "Oinam", "Ongnyei", "Porompat", "Patsoi", "Senapati", "Sugnu", "Sadar Hills", "Thoubal", "Tengnoupal", "Ukhrul", "Wangoi"]
        },
        {
            state: "Meghalaya",
            cities: ["Adokgre", "Ampati", "Shillong", "Baghmara", "Bara Basti", "Bodoland", "Cherapunji", "Chedema", "Dawki", "Dalu", "Damra", "Guwahati", "Garo Hills", "Jowai", "Jengjal", "Jaintia Hills", "Khliehriat", "Khanduli", "Mairang", "Mawlai", "Mawlynnong", "Mawngap", "Nongstoin", "Nongpoh", "Pynursla", "Polo", "Sohra", "Smit", "Tura", "Umroi", "Williamnagar", "Wahkhen"]
        },
        {
            state: "Mizoram",
            cities: ["Aizawl", "Adaklu", "Bualpui", "Biate", "Champhai", "Chhura", "Darlawn", "Dawn", "Hnahthial", "Hmar", "Hualngohm", "Ikos", "Iluang", "Kolasib", "Khawzawl", "Kawnpui", "Lunglei", "Lawngtlai", "Mamit", "Mualkawi", "Mizoram University", "Ngopa", "N. H. 54", "Pukpui", "Phuaibuang", "Siaha", "Serchhip", "Saitual", "Tlabung", "Tawi", "Ukhawthar", "Vairengte", "Vengthlang"]
        },
        {
            state: "Nagaland",
            cities: ["Atoizu", "Aghunato", "Bokajan", "Bhandari", "Chümoukedima", "Changtongya", "Dimapur", "Doyang", "Dzükou", "Kohima", "Kiphire", "Kezou", "Mokokchung", "Mon", "Noklak", "Nagaland University", "Phek", "Peren", "Pughoboto", "Wokha", "Zunheboto"]
        },
        {
            state: "Odisha",
            cities: ["Angul", "Aska", "Athgarh", "Astaranga", "Bhubaneswar", "Balasore", "Berhampur", "Bargarh", "Baripada", "Balangir", "Cuttack", "Chandbali", "Chhatrapur", "Dhenkanal", "Debagarh", "Digapahandi", "Daspalla", "Ganjam", "Gopalpur", "Gunupur", "Jajpur", "Jeypore", "Jharsuguda", "Jagatsinghpur", "Khurda", "Kendrapara", "Koraput", "Kalahandi", "Kendujhar", "Mayurbhanj", "Malkangiri", "Manoharpur", "Nayagarh", "Nabarangpur", "Paradip", "Puri", "Phulbani", "Pallahara", "Rayagada", "Rourkela", "Rairangpur", "Sambalpur", "Sundargarh", "Sonepur", "Salepur", "Titlagarh", "Taptapani", "Umerkote", "Utkela", "Vijayawada"]
        },
        {
            state: "Punjab",
            cities: ["Amritsar", "Abohar", "Attari", "Ajnala", "Bathinda", "Barnala", "Banga", "Batala", "Chandigarh", "Dasuya", "Dhuri", "Dera Baba Nanak", "Ferozepur", "Faridkot", "Fatehgarh Sahib", "Gurdaspur", "Ganganagar", "Hoshiarpur", "Haldwani", "Jalandhar", "Jagraon", "Jaitu", "Kapurthala", "Kharar", "Kotkapura", "Ludhiana", "Moga", "Mansa", "Malerkotla", "Mohali", "Nawanshahr", "Nabha", "Patiala", "Phagwara", "Patti", "Rupnagar", "Sangrur", "Sultanpur Lodhi", "Sahibzada Ajit Singh Nagar", "Tarn Taran", "Talwandi Sabo", "Zira"]
        },
        {
            state: "Rajasthan",
            cities: ["Ajmer", "Alwar", "Abu Road", "Anta", "Bikaner", "Bhilwara", "Bundi", "Barmer", "Banswara", "Bharatpur", "Churu", "Dausa", "Dungarpur", "Deeg", "Dholpur", "Jaipur", "Jaisalmer", "Jodhpur", "Jhalawar", "Ganganagar", "Kota", "Kishangarh", "Karauli", "Luni", "Ladnun", "Mount Abu", "Makrana", "Merta City", "Nagaur", "Nimbahera", "Pali", "Phalodi", "Ranthambore", "Rajasthan University", "Sikar", "Sawai Madhopur", "Sirohi", "Shekhawati", "Tonk", "Tarnau", "Udaipur", "Udaipurwati", "Zarapur"]
        },
        {
            state: "Sikkim",
            cities: ["Aritar", "Aho", "Bagdogra", "Bokar", "Chamling", "Chungthang", "Gangtok", "Dzuluk", "Gyalshing", "Kabi Lungchok", "Lachen", "Lachung", "Lingtam", "Mangan", "Martam", "Nayabazar", "Nathang", "Pemayangtse", "Pelling", "Rongli", "Rangpo", "Singtam", "Soreng", "Sichey", "Tathangchen", "Tashiding", "Upper Tadong", "West Sikkim"]
        },
        {
            state: "Tamil Nadu",
            cities: ["Ariyalur", "Ambur", "Alangudi", "Arakkonam", "Bhavani", "Bodinayakanur", "Chennai", "Coimbatore", "Cuddalore", "Chengalpattu", "Dindigul", "Dharmapuri", "Devanampatti", "Erode", "Elanthoor", "Kanchipuram", "Gudiyattam", "Hosur", "Indian Institute of Technology (IIT), Madras", "Ilagai", "Jagir", "Jolarpettai", "Kumbakonam", "Karaikudi", "Kanyakumari", "Kodaikanal", "Krishnagiri", "Lalgudi", "Madurai", "Mettur", "Mayiladuthurai", "Manapparai", "Nagercoil", "Namakkal", "Natham", "Nagapattinam", "Ooty", "Omalur", "Pudukkottai", "Palladam", "Pollachi", "Perambalur", "Ramanathapuram", "Rathinapuri", "Salem", "Sivakasi", "Sriperumbudur", "Sankari", "Tiruchirappalli", "Tirunelveli", "Tirupur", "Tiruvannamalai", "Thanjavur", "Udumalpet", "Uthangarai", "Vellore", "Villupuram", "Wellington"]
        },
        {
            state: "Telangana",
            cities: ["Adilabad", "Armoor", "Asifabad", "Ameenpur", "Bhadradri Kothagudem", "Bodhan", "Bhongir", "Chevella", "Dharmapuri", "Devarkadra", "Doulapur", "Hyderabad", "Gadwal", "Gajwel", "Hanumakonda", "Huzurabad", "Husnabad", "Jagtial", "Jangaon", "Jammikunta", "Karimnagar", "Khammam", "Kothagudem", "Kodad", "Kagaznagar", "Laxmidevipalli", "Medak", "Mahbubnagar", "Mancherial", "Miryalaguda", "Nalgonda", "Nizamabad", "Nagarkurnool", "Peddapalli", "Pochampally", "Ranga Reddy", "Rajanna Sircilla", "Ramagundam", "Suryapet", "Siddipet", "Shadnagar", "Tandur", "Telangana University", "Tirumalapur", "Uppal", "Utnur", "Vikarabad", "Vanaparthi", "Warangal", "Waddepalli", "Yadadri"]
        },
        {
            state: "Tripura",
            cities: ["Agartala", "Ambassa", "Bishalgarh", "Belonia", "Bagafa", "Kailashahar", "Chandrapur", "Churaibari", "Dharmanagar", "Dhalai", "Durgachowmuhani", "Hailakandi", "Khowai", "Lala", "Mohanpur", "Melaghar", "North Tripura", "Pecharthal", "Pratapgarh", "Rangamati", "Rudrasagar", "Sabroom", "Sonamura", "Sasthaban", "Teliamura", "Udaipur"]
        },
        {
            state: "Uttar Pradesh",
            cities: ["Agra", "Aligarh", "Allahabad", "Amroha", "Azamgarh", "Bareilly", "Basti", "Bulandshahr", "Banda", "Bahraich", "Ballia", "Chitrakoot", "Kanpur", "Deoria", "Dhampur", "Dadri", "Farrukhabad", "Faizabad", "Fatehpur", "Ghaziabad", "Gorakhpur", "Greater Noida", "Gautam Buddha Nagar", "Gonda", "Hardoi", "Hapur", "Jhansi", "Jalaun", "Jaunpur", "Jannipur", "Kushinagar", "Kanauj", "Kasganj", "Lucknow", "Lalitpur", "Meerut", "Mathura", "Muzaffarnagar", "Mainpuri", "Moradabad", "Noida", "Nagina", "Nawabganj", "Orai", "Onra", "Prayagraj", "Pilibhit", "Raebareli", "Rampur", "Rae Bareli", "Saharanpur", "Sambhal", "Sitapur", "Shahjahanpur", "Sultanpur", "Tundla", "Tajganj", "Unnao", "Varanasi", "Vikramshila", "Yamuna Nagar"]
        },
        {
            state: "Uttarakhand",
            cities: ["Almora", "Auli", "Augustmuni", "Bageshwar", "Bhowali", "Badrinath", "Chakrata", "Champawat", "Dehradun", "Dharampur", "Dhanolti", "Haridwar", "Haldwani", "Jaspur", "Joshimath", "Jwalapur", "Kashipur", "Karanprayag", "Kedarnath", "Lansdowne", "Lalkuan", "Mussoorie", "Muni Ki Reti", "Mahatma Gandhi Chauraha", "Nainital", "Narendra Nagar", "Naugaon", "Pithoragarh", "Rishikesh", "Pantnagar", "Rudrapur", "Roorkee", "Srinagar", "Sahastradhara", "Siddharthnagar", "Tehri", "Tihri", "Uttarkashi", "Udham Singh Nagar", "Vikasnagar"]
        },
        {
            state: "West Bengal",
            cities: ["Asansol", "Alipurduar", "Arambagh", "Alambazar", "Burdwan", "Bankura", "Birbhum", "Bongaon", "Bally", "Baranagar", "Kolkata", "Chandannagar", "Cooch Behar", "Durgapur", "Darjeeling", "Diamond Harbour", "Dinhata", "Egra", "Enayetnagar", "Farakka", "Fulbari", "Garia", "Howrah", "Haldia", "Hooghly", "Habra", "Ichchhpur", "Islampur", "Jalpaiguri", "Jadavpur", "Jalalpur", "Kurseong", "Kalyani", "Kharagpur", "Lakshmikantapur", "Lalbagh", "Malda", "Midnapore", "Murarai", "Maldah", "Nadia", "Nabagram", "Naihati", "Purulia", "Panshkura", "Panihati", "Ranishwar", "Raiganj", "Raghunathganj", "Siliguri", "Santiniketan", "Suri", "Serampore", "Tamluk", "Tarakeswar", "Tehatta", "Uttarpara", "Uluberia"]
        }
    ];
    const community = ['Sayyed', 'Non-Sayyed', 'Khoja', 'Converted Shia'];
    // const communitylow = ['shia', 'shia bohra', 'shia imami ismaili', 'shia zaidi', 'shia ithna ashariyyah', 'khoja'];

    useEffect(()=> window.scrollTo(0,0), []);

    const canonicalurl = window.location.href;

    useEffect(()=> {
        const localsearch = localStorage.getItem('localsearch');
        if(localsearch && localsearch !== ''){
            setIshome(true);
            setSearchpop('flex');
            localStorage.removeItem('localsearch');
        }
    }, [])

    useEffect(()=> {
            fetch(`https://azdawaj.com/api/`).then(res => res.ok? res.json(): res.status).then(data => {
                setProfile(data);
            }).catch(err => console.log('error occured while getting all the profiles in explore page ', err));
    }, []);

    useEffect(()=>{
        setFilpro(profiles);
    }, [profiles])

    useEffect(() => {
        const handleContextMenu = (event) => {
          event.preventDefault();
        };
        const handleKeyDown = (event) => {
          // F12 key
          if (event.keyCode === 123) {
            event.preventDefault();
          }
          // Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U
          if ((event.ctrlKey && event.shiftKey && (event.keyCode === 73 || event.keyCode === 74 || event.keyCode === 67)) || 
              (event.ctrlKey && event.keyCode === 85)) {
            event.preventDefault();
          }
        };
        // Attach the event listeners
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('keydown', handleKeyDown);
        // Cleanup the event listeners on component unmount
        return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if(comfil !== 'All'){
            const comifilpro = profiles?.filter(e => e.community === comfil);
            setFilpro(comifilpro);
        }else{
            setFilpro(profiles);
        }
    }, [profiles, comfil])

    // useEffect(()=> {
    //     console.log(searchjson);
    // }, [searchjson]);

    const searchFunc = () => {
        if(searchjson?.text === ''){
            // const genderFiltered = profiles?.filter(e => e.gender.toLowerCase() === searchjson.gender.toLowerCase());
            // const locationFilteredone = genderFiltered.filter(e => e.currentlocation.toLowerCase().includes(searchjson.city.toLowerCase()));
            // // const locationFilteredtwo = genderFiltered.filter(e => searchjson.city.toLowerCase().includes(e.currentlocation.toLowerCase()));
            // const locationFiltered = [...locationFilteredone];
            // const maritalFiltered = locationFiltered.filter(e => e.maritalstatus.toLowerCase() === searchjson.maritalstatus.toLowerCase())
            // setFilpro(maritalFiltered);
            const genderFiltered = profiles?.filter(e => e.gender.toLowerCase() === searchjson.gender.toLowerCase());

            const locationFiltered = genderFiltered.filter(e => 
            e.currentlocation.toLowerCase().includes(searchjson.city.toLowerCase())
            );

            const maritalFiltered = locationFiltered.filter(e => 
            e.maritalstatus.toLowerCase() === searchjson.maritalstatus.toLowerCase()
            );

            setFilpro(maritalFiltered);
        }else{
            if(Array.from(searchjson.text.trim()).splice(0, 2).join('') === 'SW'){
                const firstnameFiltered = profiles?.filter(e=> e.randomid.toLowerCase() === searchjson.text.trim().toLowerCase())
                setFilpro(firstnameFiltered);
                // console.log(firstnameFiltered);
            }else{
                const proffessionFiltered = profiles?.filter(e => e.jobtitle.toLowerCase().includes(searchjson.text.toLowerCase().trim()));
                const comuFil = profiles?.filter(e => e.community.toLowerCase().includes(searchjson.text.toLowerCase()));
                console.log(searchjson, comuFil);
                setFilpro([...comuFil, ...proffessionFiltered]);
            }
        }
    }

  return (
    <div className='main-explore'>
        <Helmet>
            <link rel="canonical" href={canonicalurl} />
        </Helmet>
        <div className="container">
            <div className="explore-search-box pt-4 d-flex">
                <Link to='/' className='btn btn-white px-0 rounded-pill me-3'><i className="fas fa-arrow-left" onClick={()=> setFilpro(profiles)}></i></Link>
                <input type="text" className="form-control rounded-pill shadow-none" placeholder='Search here' onClick={()=> setSearchpop('flex')}/>
                <button className='btn btn-brand-pink rounded-pill ms-1'><i className="fas fa-search"></i></button>
            </div>
            <div className="comunity-filter-tab mt-4 w-100 d-flex pb-3">
                <p className={`mb-0 px-3 py-2 text-nowrap btn ${comfil === 'All'? 'btn-brand-pink': 'btn-outline-brand-pink'} rounded-pill me-2`} onClick={(e)=> {setComfil(e.target.innerText)}}>All</p>
                {
                    community.map((com, ind) => (
                        <p key={ind} className={`mb-0 px-3 py-2 text-nowrap btn ${comfil === com? 'btn-brand-pink': 'btn-outline-brand-pink'} rounded-pill me-2`} onClick={(e)=> {setComfil(e.target.innerText)}}>{com}</p>
                    ))
                }
            </div>
            <div className="listing-card-wrap mt-3 row pb-5 mb-5">
                {
                    filpro?.length > 0 ? (
                        filpro?.map((pro, ind) => (
                            <div key={ind} className="col-md-4 mb-3">
                                <Listingcard userid={pro.randomid} verified={pro.verified} blur={pro.profileblur} img={`https://azdawaj.com/uploads/${pro.profileimage}`} firstname={pro.firstname} lastname={pro.lastname} proffessoin={pro.jobtitle} age={pro.dob} location={pro.currentlocation}/>
                            </div>
                        ))
                    ):(
                        <p className="text-center">No user found</p>
                    )
                }
            </div>
        </div>
        <div className="search-popup-wrap" style={{display: searchpop}}>
            <div className="search-popup bg-white p-3 pt-5">
                <div className="container p-0">
                    <p className="backbtn-detail backbtn-detail-explore mb-0 max-content cursor-pointer" onClick={()=> setSearchpop('none')}><i className="fas fa-times fs-5 text-white"></i></p>
                </div>
                <h2 className="heading fs-5 mb-3 ps-2">Search</h2>
                <div className="popup-search-input">
                    <input type="text" className='form-control shadow-none rounded-pill p-2 px-3' placeholder='Search by user id, proffession or cast' value={searchjson.text} onChange={(e) => setSearchjson(x => ({...x, text: e.target.value}))} />
                </div>
                <div className="location-div-wrap mt-4">
                    <p className="mb-0 heading fs-6 ps-2 mb-2">Gender</p>
                    <div className="d-flex">
                        <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 ${searchjson.gender === 'Male' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, gender: e.target.innerText}))}>Male</p>
                        <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 ${searchjson.gender === 'Female' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, gender: e.target.innerText}))}>Female</p>
                    </div>
                </div>
                <div className="location-div-wrap mt-4 d-none">
                    <p className="mb-0 heading fs-6 ps-2 mb-2">Location <span className='text-muted'>(Optional)</span></p>
                    <div className="row px-2">
                        <div className="col-6 pe-1">
                            <p className="mb-0 mt-2 mb-1">Country</p>
                            <input type='text' className="mb-0 w-100 rounded-3 p-2 px-3 body-font-medium border me-2 text-capitalize" value={searchjson.country} onChange={(e)=> setSearchjson(x=> ({...x, country: e.target.value}))} placeholder='Country'/>
                        </div>
                        <div className="col-6 ps-1">
                            <p className="mb-0 mt-2 mb-1">City</p>
                            <input type='text' className="mb-0 w-100 rounded-3 p-2 px-3 body-font-medium border me-2 text-capitalize" value={searchjson.city} onChange={(e)=> setSearchjson(x=> ({...x, city: e.target.value}))} placeholder='City'/>
                        </div>
                    </div>
                </div>
                <div className="location-wrap mt-4">
                    <p className="heading mb-2 fs-6 ps-2">Location <span className="text-muted">(Optional)</span></p>
                    <p className="mb-0 ps-2">India</p>
                    <div className="state-n-city-wrap row p-0 m-0">
                        <div className="col-6 ps-2">
                            <div className="state-wrap">
                                <div className="border d-flex justify-content-between align-items-center px-3 py-2 rounded-3 w-100" onClick={()=> setStatedrop(statedrop === 'none'? 'block': "none")}>
                                    <input type='text' className="mb-0 form-control shadow-none outline-none border-0 p-0" placeholder='State' autoComplete='off' value={state} onChange={(e)=> setState(e.target.value)}/> <i className="fas fa-chevron-down text-muted"></i>
                                </div>
                                <div className="state-wraper" style={{display: statedrop}}>
                                    <div className="state-drop-wrap">
                                        {
                                            allcities?.map((stat, ind) => (
                                                <p key={ind} className="mb-0 p-2 bg-brand-light-pink cursor-pointer" onClick={(e)=> {setStatedrop('none'); setState(e.target.innerText)}}>{stat.state}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 ps-0 pe-2">
                            <div className="state-wrap">
                                <div className="border d-flex justify-content-between align-items-center px-3 py-2 rounded-3 w-100" onClick={()=> setCitydrop(citydrop === 'none'? 'block': "none")}>
                                    <input type='text' className="mb-0 form-control shadow-none outline-none border-0 p-0" placeholder='City' autoComplete='off' value={searchjson?.city} onChange={(e)=> setSearchjson(x=> ({...x, city: e.target.value}))}/> <i className="fas fa-chevron-down text-muted"></i>
                                </div>
                                <div className="state-wraper" style={{display: citydrop}}>
                                    <div className="state-drop-wrap">
                                        {
                                            allcities?.map((stat)=> (
                                                stat.state === state && (
                                                    stat?.cities?.map((city, ind) => (
                                                        <p key={ind} className="mb-0 p-2 bg-brand-light-pink cursor-pointer" onClick={(e)=> {setCitydrop('none'); setSearchjson(x=> ({...x, city: e.target.innerText}))}}>{city}</p>
                                                    ))
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="location-div-wrap mt-4">
                    <p className="mb-0 heading fs-6 ps-2 mb-2">Marital Status</p>
                    <div className="d-flex flex-wrap">
                    <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 mb-2 ${searchjson.maritalstatus === 'Never Married' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, maritalstatus: e.target.innerText}))}>Never Married</p>
                        <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 mb-2 ${searchjson.maritalstatus === 'Married' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, maritalstatus: e.target.innerText}))}>Married</p>
                        <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 mb-2 ${searchjson.maritalstatus === 'Widow' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, maritalstatus: e.target.innerText}))}>Widow</p>
                    </div>
                </div>
                <div className="search-btn-wrap p-2">
                    <Link to='/explore' className="btn btn-brand-pink w-100 rounded-pill fs-5 body-font-bold" onClick={()=> {setSearchpop('none'); searchFunc()}}>Search</Link>
                </div>
            </div>
        </div>
        {
            !islogged && (
                ishome? (
                    <div className="search-popup-wrap" style={{display: searchpop}}>
                        <div className="search-popup bg-white p-2 pt-5">
                            <div className="container p-0">
                                <p className="backbtn-detail backbtn-detail-explore mb-0 max-content cursor-pointer" onClick={()=> {navigate('/'); localStorage.removeItem('localsearch'); setIshome(false)}}><i className="fas fa-times fs-5 text-white"></i></p>
                            </div>
                            <h2 className="heading fs-5 mb-3">Search</h2>
                            <div className="popup-search-input">
                                <input type="text" className='form-control shadow-none rounded-pill p-2 px-3' placeholder='Search by user id, proffession or cast' value={searchjson.text} onChange={(e) => setSearchjson(x => ({...x, text: e.target.value}))} />
                            </div>
                            <div className="location-div-wrap mt-4">
                                <p className="mb-0 heading fs-6 ps-2 mb-2">Gender</p>
                                <div className="d-flex">
                                    <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 ${searchjson.gender === 'Male' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, gender: e.target.innerText}))}>Male</p>
                                    <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 ${searchjson.gender === 'Female' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, gender: e.target.innerText}))}>Female</p>
                                </div>
                            </div>
                            <div className="location-div-wrap mt-4 d-none">
                                <p className="mb-0 heading fs-6 ps-2 mb-2">Location</p>
                                <div className="row px-2">
                                    <div className="col-6 pe-1">
                                        <p className="mb-0 mt-2 mb-1">Country</p>
                                        <input type='text' className="mb-0 w-100 rounded-3 p-2 px-3 body-font-medium border me-2 text-capitalize" value={searchjson.country} onChange={(e)=> setSearchjson(x=> ({...x, country: e.target.value}))} placeholder='Country'/>
                                    </div>
                                    <div className="col-6 ps-1">
                                        <p className="mb-0 mt-2 mb-1">City</p>
                                        <input type='text' className="mb-0 w-100 rounded-3 p-2 px-3 body-font-medium border me-2 text-capitalize" value={searchjson.city} onChange={(e)=> setSearchjson(x=> ({...x, city: e.target.value}))} placeholder='City'/>
                                    </div>
                                </div>
                            </div>
                            <div className="location-wrap mt-4">
                                <p className="heading mb-2 fs-6 ps-2">Location <span className="text-muted">(Optional)</span></p>
                                <p className="mb-0 ps-2">India</p>
                                <div className="state-n-city-wrap row p-0 m-0">
                                    <div className="col-6 ps-2">
                                        <div className="state-wrap">
                                            <div className="border d-flex justify-content-between align-items-center px-1 py-2 rounded-3 w-100" onClick={()=> setStatedrop(statedrop === 'none'? 'block': "none")}>
                                                <input type='text' className="mb-0 form-control shadow-none outline-none border-0 p-0" placeholder='State' autoComplete='off' value={state} onChange={(e)=> setState(e.target.value)}/> <i className="fas fa-chevron-down text-muted"></i>
                                            </div>
                                            <div className="state-wraper" style={{display: statedrop}}>
                                                <div className="state-drop-wrap">
                                                    {
                                                        allcities?.map((stat, ind) => (
                                                            <p key={ind} className="mb-0 p-2 bg-brand-light-pink cursor-pointer" onClick={(e)=> {setStatedrop('none'); setState(e.target.innerText)}}>{stat.state}</p>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 ps-0 pe-2">
                                        <div className="state-wrap">
                                            <div className="border d-flex justify-content-between align-items-center px-2 py-2 rounded-3 w-100" onClick={()=> setCitydrop(citydrop === 'none'? 'block': "none")}>
                                                <input type='text' className="mb-0 form-control shadow-none outline-none border-0 p-0" placeholder='City' autoComplete='off' value={searchjson?.city} onChange={(e)=> setSearchjson(x=> ({...x, city: e.target.value}))}/> <i className="fas fa-chevron-down text-muted"></i>
                                            </div>
                                            <div className="state-wraper" style={{display: citydrop}}>
                                                <div className="state-drop-wrap">
                                                    {
                                                        allcities?.map((stat)=> (
                                                            stat.state === state && (
                                                                stat?.cities?.map((city, ind) => (
                                                                    <p key={ind} className="mb-0 p-2 bg-brand-light-pink cursor-pointer" onClick={(e)=> {setCitydrop('none'); setSearchjson(x=> ({...x, city: e.target.innerText}))}}>{city}</p>
                                                                ))
                                                            )
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="location-div-wrap mt-4">
                                <p className="mb-0 heading fs-6 ps-2 mb-2">Marital Status</p>
                                <div className="d-flex flex-wrap">
                                <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 mb-2 ${searchjson.maritalstatus === 'Never Married' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, maritalstatus: e.target.innerText}))}>Never Married</p>
                                    <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 mb-2 ${searchjson.maritalstatus === 'Married' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, maritalstatus: e.target.innerText}))}>Married</p>
                                    <p className={`mb-0 btn rounded-pill p-2 px-3 body-font-medium me-2 mb-2 ${searchjson.maritalstatus === 'Widow' ? 'btn-brand-pink': 'btn-outline-brand-pink'}`} onClick={(e)=> setSearchjson(x => ({...x, maritalstatus: e.target.innerText}))}>Widow</p>
                                </div>
                            </div>
                            <div className="search-btn-wrap p-2">
                                <Link to='/explore' className="btn btn-brand-pink w-100 rounded-pill fs-5 body-font-bold" onClick={()=> {localStorage.removeItem('localsearch'); setIshome(false)}}>Search</Link>
                            </div>
                        </div>
                    </div>
                ):(
                    <Signuppop/>
                )
            )
        }
    </div>
  )
}

export default Explore;