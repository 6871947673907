import React, { useEffect, useState } from 'react';
import './Dashmenu.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Dashmenu() {

    const { decodeJSON } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [nav, setNav] = useState(window.innerWidth < 780? "-100vw": "0vw");
    const [isroot, setIsroot] = useState(true);
    const [admin, setAdmin] = useState({});
    const [admintype, setAdmintype] = useState('');

    useEffect(()=> {
        const localuser = localStorage.getItem('adlocprodat');
        if(!localuser || localuser.length < 1){
            const declocaluser = decodeJSON(localuser);
            if(declocaluser.usertype !== 'superadmin'){
                setAdmintype(declocaluser.usertype);
            }
        }
    }, [decodeJSON])

    useEffect(()=> {
        // console.log('this is admin type ', admintype, admin);
        setAdmintype(admin.usertype)
    }, [admintype, admin]);

    useEffect(()=> {
        if(window.location.pathname === '/admin/'){
            setIsroot(true);
        }else{
            setIsroot(false);
        }

        const adlocprodat = localStorage.getItem('adlocprodat');
        if(adlocprodat){
            setAdmin(decodeJSON(adlocprodat))
        }
    }, [decodeJSON]);

    const logoutFunc = () => {
        localStorage.removeItem("adlocprodat");
        navigate('/admin/')
    }

    const closeNav = () => {
        if(window.innerWidth < 800){
            setNav('-100vw');
        }
    }

  return (
    <div className={`main-dashmenu ${isroot && 'd-none'}`}>
        <div className="ham-wraper w-100 p-3 pb-2 pt-0 bg-white">
            <div className="ham-wrap cursor-pointer w-max-content" onClick={()=> setNav(nav === '-100vw'? '0vw': '-100vw')}>
                <div className="ham"></div>
                <div className="ham"></div>
            </div>
        </div>
        <div className="dashmenu-wrap d-flex flex-column justify-content-between bg-brand-light-pink p-3 rounded-brand" style={{marginLeft: nav}}>
            <div className="dashlinks-wrap">
                <Link to='dashboard' className='nav-link' onClick={closeNav}>
                    <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === `/admin/dashboard` || location.pathname === '/admin/'? 'bg-white body-font-bold': ''}`}><i className="fas fa-th-large me-2"></i> Dashboard</p>
                </Link>
                <Link to='profiles/all' className='nav-link' onClick={closeNav}>
                    <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === `/admin/profiles/all`? 'bg-white body-font-bold': ''}`}><i className="fas fa-user me-2"></i> Profiles</p>
                </Link>
                {
                    admintype === 'superadmin' && (
                        <Link to='admins' className='nav-link' onClick={closeNav}>
                            <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === `/admin/admins`? 'bg-white body-font-bold': ''}`}><i className="fas fa-users-cog me-2"></i> Admins</p>
                        </Link>
                    )
                }
                <Link to='createprofile' className='nav-link' onClick={closeNav}>
                    <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === '/admin/createprofile'? 'bg-white body-font-bold': ''}`}><i className="fas fa-plus me-2"></i> Create Profile</p>
                </Link>
                <Link to='verification-requests' className='nav-link' onClick={closeNav}>
                    <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === '/admin/verification-requests'? 'bg-white body-font-bold': ''}`}><i className="fas fa-user-check me-2"></i> Verify Profiles</p>
                </Link>
                <Link to='deletion-requests' className='nav-link' onClick={closeNav}>
                    <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === '/admin/deletion-requests'? 'bg-white body-font-bold': ''}`}><i className="fas fa-trash me-2"></i> Delete Profiles</p>
                </Link>
                <Link to='reports' className='nav-link' onClick={closeNav}>
                    <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === '/admin/reports'? 'bg-white body-font-bold': ''}`}><i className="fas fa-info me-2"></i> Reported</p>
                </Link>
                {
                    admintype === 'superadmin' && (
                        <Link to='createadmin' className='nav-link' onClick={closeNav}>
                            <p className={`dashmenu-links mb-0 p-3 rounded-brand-sm ${location.pathname === '/admin/createadmin'? 'bg-white body-font-bold': ''}`}><i className="fas fa-user-plus me-2"></i> Create Admin</p>
                        </Link>
                    )
                }
            </div>
            <div className="dashlinks-wrap">
                <p className="dashmenu-links mb-0 p-2 px-3 rounded-brand-sm text-capitalize"><i className="fas fa-user-cog me-2"></i> {admin.username}</p>
                <p className="dashmenu-links mb-0 p-2 px-3 rounded-brand-sm cursor-pointer" onClick={logoutFunc}><i className="fas fa-power-off me-2"></i> Logout</p>
            </div>
        </div>
    </div>
  )
}

export default Dashmenu;